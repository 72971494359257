body.active {
  overflow: hidden;
}
.account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.account.content {
  max-width: 1335px;
  margin: 0 auto;
  padding: 0 20px;
}

.account .dropdown {
  position: relative;
}

.account .dropdown:hover .dropdown__header {
  color: #1894e7;
}

.account .dropdown:hover .dropdown__arrow {
  -webkit-transform: translateY(-80%) rotate(-180deg);
  transform: translateY(-80%) rotate(-180deg);
}

.account .dropdown:hover .dropdown__arrow path {
  fill: #1894e7;
}

.account .dropdown:hover .dropdown__body {
  display: block;
}

.account .dropdown__header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.account .dropdown__title {
  display: flex;
}

.account .dropdown__header svg {
  margin-left: .25rem;
  transition: transform .3s ease-out;
  will-change: transform;
}

.account .dropdown:hover .dropdown__header svg,
.account .dropdown:active .dropdown__header svg,
.account .dropdown:focus .dropdown__header svg {
  transform: rotate(180deg);
}

.account .sub-menu-container {
  display: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -150px;
  left: -50%;
  background: #fff;
  padding: 0;
  border-radius: 0 0 4px 4px;
  border-top: 3px solid #2A59FF;
  box-shadow: 0 3px 6px rgba(155, 155, 155, 0.16), 0 3px 6px rgba(155, 155, 155, 0.23);
  opacity: 0;
  transition: transform .3s ease-out, opacity .15s ease-out;
  will-change: opacity, transform;
  z-index: 11;
}

.account .sub-menu-container li {
  width: 90px;
  text-align: center;
  background: #fff;
  color: #434343;
  transition: background .3s ease-out;
  will-change: background;
}

.account .sub-menu-container li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .5rem 0;
  background: #fff;
  color: #434343;
  transition: color .3s ease-out, background .3s ease-out;
  will-change: color, background;
}

.account .sub-menu-container li a.active {
  color: #2A59FF;
}

.account .sub-menu-container li a:hover,
.account .sub-menu-container li a:active,
.account .sub-menu-container li a:focus {
  background: #F5F5F5;
  color: #2A59FF;
}

.account .dropdown:hover .sub-menu-container,
.account .dropdown:active .sub-menu-container,
.account .dropdown:focus .sub-menu-container {
  display: flex;
  transform: translateY(225px);
  opacity: 1;
}

.account .lang {
  margin-right: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width: 460px) {
  .account .lang--hide {
    display: none;
  }
}

.account.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 20px;
  min-height: calc(100vh - 200px);
}

@media (max-width: 767.68px) {
  .account.main {
    min-height: auto;
  }
}

.account .main-sidebar {
  max-width: 170px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
}

@media screen and (max-width: 767.68px) {
  .account .main-sidebar {
    display: none;
  }
}

.account .main__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;
}

.account .main__content .breadcrumbs__container {
  padding: 0
}

.account .copy {
  font-size: 12px;
  color: #9d9d9d;
  line-height: 18px;
}

.account .projects__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: 6px 12px 14px rgba(49, 49, 49, 0.04);
  box-shadow: 6px 12px 14px rgba(49, 49, 49, 0.04);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 17px 25px;
}

@media screen and (max-width: 520px) {
  .account .projects__header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 475px) {
  .account .projects__header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 13px;
  }
}

.account .projects__add-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 3px 5px 15px rgba(20, 69, 193, 0.15);
  box-shadow: 3px 5px 15px rgba(20, 69, 193, 0.15);
  border-radius: 10px;
  background-color: #2A59FF;
  outline: none;
  border: none;
  color: #ffffff;
  padding: 10px 24px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .account .projects__add-btn {
    padding: 7px 24px;
  }
}

@media screen and (max-width: 475px) {
  .account .projects__add-btn {
    font-size: 12px;
    padding: 7px 10px;
  }
}

.account .projects__add-btn:hover {
  background-color: #0077c6;
}

.account .projects__add-icon {
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

@media screen and (max-width: 991px) {
  .account .projects__add-icon {
    font-size: 18px;
  }
}

.account .projects__add-text {
  display: block;
  font-weight: 500;
  line-height: 20px;
  padding-left: 9px;
}

.account .section-title {
  line-height: 30px;
  font-size: 2rem;
  font-weight: 500;
  color: #2A59FF;
  margin-bottom: 0.25rem;
}

@media screen and (max-width: 991px) {
  .account .section-title {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 520px) {
  .account .section-title {
    font-size: 18px;
  }
}

.account .sidebar-info {
  margin-left: 30px;
  padding-top: 25px;
}

@media screen and (max-width: 1200px) {
  .account .sidebar-info {
    display: none;
  }
}

.account .sidebar-info__item {
  padding-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .account .sidebar-info__item {
    padding-bottom: 0;
    padding-top: 30px;
  }
}

.account .sidebar-info__item--mobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .account .sidebar-info__item--mobile {
    display: block;
  }
}

.account .sidebar-info__title {
  font-size: 27px;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .account .sidebar-info__title {
    font-size: 20px;
  }
}

@media screen and (max-width: 767.68px) {
  .account .sidebar-info__title {
    font-size: 16px;
  }
}

.account .sidebar-info__prev {
  -webkit-box-shadow: 0 3px 6px rgba(49, 49, 49, 0.04);
  box-shadow: 0 3px 6px rgba(49, 49, 49, 0.04);
  border-radius: .5rem;
  background-color: #fff;
  padding: 10px;
  min-width: 167px;
  min-height: 70px;
  margin-bottom: 20px;
  transition: transform .3s ease-out, box-shadow .3s ease-out;
  will-change: transform, box-shadow;
  cursor: default;
  width: 170px;
}

.account .sidebar-info__prev:hover,
.account .sidebar-info__prev:active,
.account .sidebar-info__prev:focus {
  transform: translateY(-5px);
  -webkit-box-shadow: 0 6px 12px rgba(49, 49, 49, 0.04);
  box-shadow: 0 6px 12px rgba(49, 49, 49, 0.04);
}

@media screen and (max-width: 1200px) {
  .account .sidebar-info__prev {
    min-width: 100%;
  }
}

.account .score-item__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.account .score-item__name {
  display: block;
}

.account .score-item__value {
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
  line-height: normal;
}

@media screen and (max-width: 767.68px) {
  .account .score-item__value {
    font-size: 14px;
  }
}

.account .score-item__value--currency {
  display: inline;
  color: #939393;
}

.account .actions-item__title {
  display: block;
  font-size: 12px;
  max-width: 130px;
  line-height: normal;
}

.account .actions-item__amount {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #434343;
  max-width: 130px;
  margin-top: .5rem;
}

.account .code-text {
  color: #939393;
}

.account .actions-item__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 10px;
}

.account .actions-item__date-name {
  display: block;
  font-size: 10px;
  color: #727272;
  padding-top: 2px;
  padding-left: 5px;
  padding-left: 5px;
  line-height: normal;
}

.account .projects__stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 5px;
}

.account .projects__stats-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 18px;
  width: 48%;
  -webkit-box-shadow: 6px 12px 14px rgba(49, 49, 49, 0.04);
  box-shadow: 6px 12px 14px rgba(49, 49, 49, 0.04);
  border-radius: 10px;
  background-color: #87cfff;
  min-height: 90px;
  color: #ffffff;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: right center;
}

@media screen and (max-width: 991px) {
  .account .projects__stats-item {
    min-height: 60px;
  }
}

@media screen and (max-width: 520px) {
  .account .projects__stats-item {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .account .projects__stats-item svg {
    width: 30px;
    height: 30px;
  }
}

.account .projects__stats-num {
  display: block;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  padding-left: 10px;
}

@media screen and (max-width: 991px) {
  .account .projects__stats-num {
    font-size: 20px;
  }
}

.account .projects__stats-text {
  display: block;
  padding-left: 12px;
  font-size: 21px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 0.84px;
}

@media screen and (max-width: 991px) {
  .account .projects__stats-text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.2;
  }
}

.account .sort-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
}

.account .sort-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 20rem;
}


@media (max-width: 822px) {
  .account .sort-area {
    grid-template-columns: 1fr 1fr;
  }
}

.account .projects-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  -webkit-box-shadow: 6px 12px 14px rgba(49, 49, 49, 0.04);
  box-shadow: 6px 12px 14px rgba(49, 49, 49, 0.04);
  border-radius: 10px;
  background-color: #fff;
  padding: 1.5rem 2rem;
}

.account .projects-info__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 25px 10px;
}

.account .projects-info__all {
  color: #8f8f8f;
  padding: 10px 0;
}

.account .projects-info__all--fw {
  font-weight: 700;
}

.account .projects-info__next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  color: #727272;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.account .projects-info__next:hover {
  color: #1894e7;
}

.account .projects-info__next:hover .projects-info__next-arrow {
  color: #1894e7;
}

.account .projects-info__next-text {
  display: block;
  padding-right: 5px;
}

.account .projects-info__next-arrow {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.account .sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #777777;
}

@media screen and (max-width: 575px) {
  .account .sort {
    font-size: 12px;
    padding-bottom: 10px;
  }
}

.account .sort__title {
  display: block;
  padding-right: 4px;
}

.account .sort__dropdown-title {
  font-weight: 700;
}

.account .sort__dropdown-arrow {
  margin-top: 2px;
}

.account .search-prev {
  position: relative;
}

.account .search-prev__field {
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  background-color: #f4f8fe;
  width: 200px;
  outline: none;
  padding: 5px 10px;
  font-size: 12px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.account .search-prev__field::-webkit-input-placeholder {
  color: #a3a3a3;
}

.account .search-prev__field:-ms-input-placeholder {
  color: #a3a3a3;
}

.account .search-prev__field::-ms-input-placeholder {
  color: #a3a3a3;
}

.account .search-prev__field::placeholder {
  color: #a3a3a3;
}

.account .search-prev__field:focus {
  background-color: #ffffff;
}

.account .search-prev__field:active {
  background-color: #ffffff;
}

.account .search-prev__field:hover {
  background-color: #f4f6f9;
}

.account .search-prev__send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
}

.account .table-projects {
  overflow: auto;
}

.account .table-projects__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 24px 16px 17px;
}

.account .table-projects__header-title {
  color: #434343;
  font-weight: 700;
}

.account .table-projects__side {
  text-align: center;
}

.account .table-projects__side--left {
  text-align: left;
}

.account .table-projects__side--right {
  text-align: right;
}

@media screen and (max-width: 991px) {
  .account .table-projects__side--right {
    text-align: center;
  }
}

.account .table-projects__side--center {
  text-align: center;
}

.account .table-projects__side--8 {
  width: 9%;
}

@media screen and (max-width: 991px) {
  .account .table-projects__side--8 {
    min-width: 150px;
  }
}

.account .table-projects__side--10 {
  width: 11%;
}

@media screen and (max-width: 991px) {
  .account .table-projects__side--10 {
    min-width: 150px;
  }
}

.account .table-projects__side--12 {
  width: 13%;
}

@media screen and (max-width: 991px) {
  .account .table-projects__side--12 {
    min-width: 150px;
  }
}

.account .table-projects__side--13 {
  width: 15%;
}

@media screen and (max-width: 991px) {
  .account .table-projects__side--13 {
    min-width: 100px;
  }
}

.account .table-projects__side--16 {
  width: 16%;
}

@media screen and (max-width: 991px) {
  .account .table-projects__side--16 {
    min-width: 100px;
  }
}

.account .table-projects__side--19 {
  width: 19%;
}

.account .table-projects__side--40 {
  width: 40%;
}

.account .table-projects__side--55 {
  width: 55%;
}

.account .mb-4 {
  margin-bottom: 2rem;
}

.account .mb-2 {
  margin-bottom: 1rem;
}

.account .ml-2 {
  margin-left: 0.5rem !important;
}

@media screen and (max-width: 991px) {
  .account .table-projects__side--19 {
    min-width: 100px;
  }

  .account .table-projects__side--40 {
    min-width: 150px;
  }

  .account .table-projects__side--55 {
    min-width: 200px;
  }
}

.account .table-projects__side--flex {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.account .table-projects__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 24px 12px 17px;
  margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
  .account .table-projects__line {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}

.account .table-projects__line:nth-child(2n) {
  background-color: transparent;
}

.account .table-projects__main-name {
  color: #434343;
  font-weight: 700;
  opacity: 0.8;
}

.account .table-projects__main-user {
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #434343;
}

.account .table-projects__main-user:hover {
  color: #2A59FF;
}

.account .table-projects__main-rating {
  color: #16c716;
  font-weight: 700;
}

.account .table-projects__main-rating--orange {
  color: #ff9c00;
}

.account .table-projects__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.account .table-projects__nav-icon {
  fill: #aaaaaa;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.account .table-projects__nav-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  outline: none;
  width: 26px;
  height: 26px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 50%;
  cursor: pointer;
}

.account .table-projects__nav-btn:hover {
  background-color: #2A59FF;
}

.account .table-projects__nav-btn:hover .table-projects__nav-icon {
  fill: #ffffff;
}

.account .projects__header-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.account .projects__add-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: .5rem;
  background-color: #2A59FF;
  outline: none;
  border: none;
  color: #fff;
  padding: .75rem 2rem;
  cursor: pointer;
  box-shadow: none;
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
  will-change: background;
}

.account .projects__add-btn i {
  margin-right: .25rem;
}

@media screen and (max-width: 475px) {
  .account .projects__add-btn {
    font-size: 12px;
    padding: 7px 10px;
  }
}

.account .projects__add-btn:hover {
  background-color: #0077c6;
}

.account .projects__add-btn:not(:last-child) {
  margin-right: 10px;
}

.account .projects__add-btn--red {
  background-color: #ff0042;
}

.account .projects__add-btn--red:hover {
  background-color: #d00036;
}

.account .projects__add-btn--green {
  background-color: #00B74A;
}

.account .projects__add-btn--green:hover {
  background-color: #03a243;
}

.account .projects__add-btn--green:active {
  background-color: #02d156;
}

.account .projects__add-svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  fill: #ffffff;
}

.account .projects__add-icon {
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

@media screen and (max-width: 991px) {
  .account .projects__add-icon {
    font-size: 18px;
  }
}

.account .projects__add-text {
  display: block;
  font-weight: 500;
  line-height: 20px;
  padding-left: 9px;
}

.account .projects__stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 5px;
}

.account .projects__stats-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 18px;
  width: 48%;
  -webkit-box-shadow: 6px 12px 14px rgba(49, 49, 49, 0.04);
  box-shadow: 6px 12px 14px rgba(49, 49, 49, 0.04);
  border-radius: 10px;
  background-color: #87cfff;
  min-height: 90px;
  color: #ffffff;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: right center;
}

@media screen and (max-width: 991px) {
  .account .projects__stats-item {
    min-height: 60px;
  }
}

@media screen and (max-width: 520px) {
  .account .projects__stats-item {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .account .projects__stats-item svg {
    width: 30px;
    height: 30px;
  }
}

.account .projects__stats-num {
  display: block;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  padding-left: 10px;
}

@media screen and (max-width: 991px) {
  .account .projects__stats-num {
    font-size: 20px;
  }
}

.account .projects__stats-text {
  display: block;
  padding-left: 12px;
  font-size: 21px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 0.84px;
}

@media screen and (max-width: 991px) {
  .account .projects__stats-text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.2;
  }
}

.account .projects-info__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1rem;
  width: 100%;
}

@media screen and (max-width: 575px) {
  .account .projects-info__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.account .projects-board {
  margin-top: 25px;
  padding: 20px 25px;
}

@media screen and (max-width: 575px) {
  .account .projects-board {
    padding: 20px 15px;
  }
}

.account .projects-board__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 2rem;
}

@media screen and (max-width: 575px) {
  .account .projects-board__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 575px) {
  .account .projects-board__logo img {
    width: 100px;
  }
}

.account .projects-board__side {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 20px;
}

@media screen and (max-width: 575px) {
  .account .projects-board__side {
    padding-left: 0;
  }
}

.account .projects-board__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.account .projects-board__group--start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media screen and (max-width: 575px) {
  .account .projects-board__group--start {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.account .projects-board__block {
  width: 100%;
  padding-bottom: .5rem;
}

@media screen and (max-width: 575px) {
  .account .projects-board__main {
    text-align: center;
  }
}

.account .projects-board__title {
  line-height: 22px;
  color: #434343;
  padding-bottom: 10px;
  font-weight: 500;
  letter-spacing: 0.64px;
}

@media screen and (max-width: 575px) {
  .account .projects-board__title {
    font-size: 14px;
  }
}

.account .projects-board__field {
  font-size: 12px;
  padding: 5px 10px;
  outline: none;
  border-radius: 5px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: 1px dashed #aaaaaa;
  margin-bottom: 20px;
  color: #434343;
  width: 180px;
}

@media screen and (max-width: 575px) {
  .account .projects-board__field {
    width: 48%;
  }
}

.account .projects-board__field::-webkit-input-placeholder {
  color: #aaaaaa;
  font-size: 12px;
}

.account .projects-board__field:-ms-input-placeholder {
  color: #aaaaaa;
  font-size: 12px;
}

.account .projects-board__field::-ms-input-placeholder {
  color: #aaaaaa;
  font-size: 12px;
}

.account .projects-board__field::placeholder {
  color: #aaaaaa;
  font-size: 12px;
}

.account .projects-board__field:hover {
  border: 1px solid #2A59FF;
}

.account .projects-board__field:focus {
  border: 1px solid #2A59FF;
}

.account .projects-board__field--130 {
  width: 130px;
}

@media screen and (max-width: 575px) {
  .account .projects-board__field--130 {
    width: 48%;
  }
}

.account .projects-board__field--160 {
  width: 160px;
}

@media screen and (max-width: 575px) {
  .account .projects-board__field--160 {
    width: 48%;
  }
}

.account .projects-board__field--140 {
  width: 140px;
}

@media screen and (max-width: 575px) {
  .account .projects-board__field--140 {
    width: 48%;
  }
}

.account .projects-board__field-name {
  width: 250px;
}

@media screen and (max-width: 575px) {
  .account .projects-board__field-name {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .account .projects-board__add-btn {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .account .projects-board__add-btn .projects__add-svg {
    width: 13px;
    height: 13px;
  }
}

@media screen and (max-width: 575px) {
  .account .projects-board__add-btn .projects__add-text {
    padding-left: 3px;
  }
}

.account .projects-board__textarea {
  width: 100%;
  height: 150px;
}

.account .projects-board__check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.account .projects-board__check:not(:last-child) {
  margin-right: 20px;
}

.account .projects-board__checkbox {
  display: none;
}

.account .select {
  position: relative;
  margin-bottom: 20px;
}

@media screen and (max-width: 575px) {
  .account .select {
    width: 48%;
  }
}

.account .select--margin {
  margin-right: 20px;
}

@media screen and (max-width: 575px) {
  .account .select--margin {
    margin-right: 0;
  }
}

.account .inline-text-field-container {
  display: flex;
  flex-direction: column;
  width: calc(50% - 1rem);
  margin-bottom: 1rem;
}

.account .inline-text-field-container.w-33 {
  width: calc(33.3% - 1rem);
}

.account .inline-text-field-container.w-100 {
  width: 100%;
}

.account .inline-text-field-container.w-75 {
  width: 75%;
}

.account .inline-text-field-container.w-25 {
  width: 25%;
}

.account .m-field:not(.disabled) {
  background-color: transparent;
}

.account .m-field {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: .25rem;
  display: flex;
  flex-direction: column;
  position: relative;
  will-change: opacity, transform, color;
  transition: all .3s linear;
}
.m-field .loading-area {
  position: absolute;
  top: .6rem;
  right: 1rem;
  font-size: 1rem;
  color: #2A59FF
}

.account .m-field-icon {
  width: 1rem;
  height: 1rem;
  left: .75rem;
  position: absolute;
  top: 10px;
  color: rgba(0, 0, 0, .3);
}

.account .mdc-text-field:not(.disabled) .m-field-icon {
  color: rgba(0, 0, 0, .54);
  z-index: 2;
}

.account .m-field-icon:not([tabindex]),
.account .m-field-icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.account .m-field-with-icon.m-field .m-field-input {
  padding-left: 2.5rem;
  padding-right: 1rem;
}

.account .m-field-with-icon.m-field .m-notch-label {
  left: 2rem;
}

.account .m-field-with-icon.m-field .m-notch-label.active {
  left: 1rem;
}

.account .mdc-text-field:not(.mdc-text-field--disabled) .m-field-input {
  color: rgba(0, 0, 0, .87);
}

.account .m-field .m-field-input {
  display: flex;
  padding: 8px 16px;
  background-color: transparent;
  z-index: 1;
  font-family: "Roboto";
}
.account .m-field textarea.m-field-input {
  max-height: 150px;
}

.account .m-field select.m-field-input {
  display: flex;
  background-color: transparent;
  z-index: 1;
  line-height: 1;
  padding: 8px 16px 6px;
}

.account .m-field.m-field-select .m-down-icon {
  position: absolute;
  top: 10px;
  right: .5rem;
  width: 1rem;
  height: 1rem;
  color: rgba(0, 0, 0, .3);
  transition: transform .2s ease-out;
  will-change: transform;
}

.account .m-field-input:focus~.m-down-icon,
.account .m-field-input:active+.m-down-icon {
  transform: rotate(180deg);
}

.account .m-field-input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .009375em;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  align-items: center;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  padding: calc(0.375em - 1px) calc(0.625em - 1px) calc(0.375em - 1px) calc(0.625em - 1px);
  transition: hover .3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: focus .15s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(0, 0, 0, .3);
  border-radius: .25rem;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea.m-field-input {
  height: auto;
  resize: vertical;
}

.account .m-field-input:hover {
  border: 1px solid rgba(0, 0, 0, .6);
}

.account .m-field-input:focus,
.account .m-field-input:active {
  outline: none;
  border: 1px solid #4285f4;
}

.account .m-field-input:focus+.m-field-icon,
.account .m-field-input:active+.m-field-icon {
  color: rgba(0, 0, 0, .6);
}

.account .m-field-input.textarea {
  height: auto;
}

.account .m-field-input.disabled {
  color: #757575;
}

.btn.disabled {
  pointer-events: none;
  opacity: .5;
  cursor: default
}

.account .m-notch-label {
  margin: 0;
  right: initial;
  color: rgba(0, 0, 0, .5);
  display: inline-block;
  position: relative;
  max-width: 100%;
  font-weight: 400;
  letter-spacing: .009375em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  transform-origin: left top;
  -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  top: 10px;
  background: transparent;
  font-size: 1rem;
  left: 1rem;
  z-index: 1;
  line-height: 1rem;
  padding: 0 .25rem;
  pointer-events: none;
}

.account .m-field-input:focus~.m-notch-label,
.account .m-field-input:active+.m-notch-label,
.account .m-notch-label.active,
.account .m-field-textarea:focus+.m-notch-label,
.account .m-field-textarea:active+.m-notch-label,
.account .m-notch-label.active {
  background: #fff;
  color: #4285f4;
  font-size: .85rem;
  z-index: 2;
  line-height: 1rem;
  padding: 0 .25rem;
  font-weight: 500;
  left: 1rem;
  transform: translate(-.5rem, -1.15rem);
}

.account .m-notch-label.active {
  color: rgba(0, 0, 0, .5);
  font-weight: 300;
}

.account .has-error .m-notch-label {
  color: #fe0000;
}

.account .has-error .m-field-input:focus,
.account .has-error .m-field-input:active {
  border-color: #fe0000;
}

#coins-area {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.account .flex-table .flex-table-row .score span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 50px;
  border-radius: 4rem;
  color: #ffffff;
  background-color: #2A59FF;
  font-weight: 500;
  font-size: .85rem;
}

.account .flex-table .flex-table-row .score span.average {
  background-color: #FFA900;
}

.account .flex-table .flex-table-row .score span.low {
  background-color: #F93154;
}

@media (max-width: 822px) {
  .account .radius-and-shadow .flex-table-row:first-child {
    display: none;
  }

  .account .radius-and-shadow .flex-table-row {
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: .25rem;
    padding: 1rem;
    background: #f6f7fb;
  }

  .account .radius-and-shadow .flex-table-row .title {
    width: 75%;
    font-weight: 500;
  }

  .account .radius-and-shadow .flex-table-row .title img {
    width: 40px;
    height: 40px;
  }

  .account .radius-and-shadow .flex-table-row .score {
    width: 25%;
  }

  .account .radius-and-shadow .flex-table-row .value {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
    text-align: left;
  }

  .account .radius-and-shadow .flex-table-row .value .hint {
    display: inline-block;
    margin-right: .25rem;
  }

  .account .radius-and-shadow .flex-table-row .value .main-value {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}

.account .flex-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
}

.account .flex-table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 300;
}

.account .table-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 300;
}

.account .flex-table-row:not(:first-child) {
  font-size: .875rem;
}

.account .flex-table-row:not(:last-child) {
  margin-bottom: 1rem;
}

.account .flex-table-row .title {
  width: 50%;
}

.account .flex-table-row .value {
  width: 30%;
}

.account .flex-table-row .value.highlited {
  width: 50%;
}

.account .flex-table-row .score {
  width: 20%;
}

.account .flex-table-row:first-child .title,
.account .flex-table-row:first-child .score,
.account .flex-table-row:first-child .value {
  font-weight: 600;
}

.account .flex-table-row .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.account .flex-table-row .score {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.account .flex-table-row .value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.account .flex-table-row .value.highlited {
  font-weight: 500;
  font-size: 1rem;
}

.account .flex-table-row .value .main-value {
  margin-bottom: .25rem;
}

.account .flex-table-row .value .percents {
  margin-bottom: 0;
  color: #00B74A;
  font-size: .75rem;
}

.account .flex-table-row .value .percents.lower {
  margin-bottom: 0;
  color: #F93154;
}

.account .flex-table-row .value .hint {
  display: none;
}

.account .flex-table-row .title.highlited {
  font-size: 1rem;
  font-weight: 400;
}

.account .flex-table-row .title img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  object-position: center;
  margin-right: .5rem;
  border-radius: 50%;
}

.account .flex-table-row .title a,
.account .table-big__td a {
  transition: color .3s ease-out;
  will-change: color;
}

.account .flex-table-row .title a:hover,
.account .table-big__td a:hover {
  color: #2A59FF;
}

.account .flex-table-row .title a:active,
.account .flex-table-row .title a:focus,
.account .table-big__td a:active,
.account .table-big__td a:focus {
  color: #00ACEE;
}

.account .flex-table-row .title img.crypto-logo {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}

.account .flex-table.coins-rating-area .flex-table-row,
.account .flex-table.coins-rating-area .table-row {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  font-size: .9rem;
  font-weight: 400;
  will-change: background;
  transition: background .3s ease-out;
}

.account .flex-table.coins-rating-area .table-row {
  width: auto;
}

@media (max-width: 1050px) {
  .account .flex-table.coins-rating-area .table-row {
    width: auto;
  }
}

.account .flex-table.coins-rating-area .flex-table-row:first-child,
.account .flex-table.coins-rating-area .table-row:first-child {
  font-weight: 600;
}

.account .flex-table.coins-rating-area .flex-table-row:nth-child(2n),
.account .flex-table.coins-rating-area .table-row:nth-child(2n) {
  background: #f6f7fb;
}

.account .flex-table.coins-rating-area .flex-table-row:not(:first-child):hover,
.account .flex-table.coins-rating-area .flex-table-row:not(:first-child):active,
.account .flex-table.coins-rating-area .flex-table-row:not(:first-child):focus,
.account .flex-table.coins-rating-area .table-row:not(:first-child):hover,
.account .flex-table.coins-rating-area .table-row:not(:first-child):active,
.account .flex-table.coins-rating-area .table-row:not(:first-child):focus {
  background: #E1F5FE;
  cursor: pointer;
}

.account .flex-table.coins-rating-area .flex-table-row a,
.account .flex-table.coins-rating-area .table-row a {
  will-change: color;
  transition: color .3s ease-out;
}

.account .flex-table.coins-rating-area .flex-table-row a:hover,
.account .flex-table.coins-rating-area .flex-table-row a:active,
.account .flex-table.coins-rating-area .flex-table-row a:focus,
.account .flex-table.coins-rating-area .table-row a:hover,
.account .flex-table.coins-rating-area .table-row a:active,
.account .flex-table.coins-rating-area .table-row a:focus {
  color: #2A59FF
}

.account .flex-table.coins-rating-area .flex-table-row .item,
.account .flex-table.coins-rating-area .table-row .item,
.account .flex-table.coins-rating-area .table-row .item .item-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.account .flex-table.coins-rating-area .table-row .item .item-name {
  margin-left: 0.5rem;
}

.account .flex-table.coins-rating-area .table-row .item {
  justify-content: flex-start;
}

.account .flex-table.coins-rating-area .flex-table-row .item.higher,
.account .flex-table.coins-rating-area .table-row .item.higher {
  color: #00B74A;
}

.account .flex-table.coins-rating-area .flex-table-row .item.lower,
.account .flex-table.coins-rating-area .table-row .item.lower {
  color: #DB4437;
}

.account .flex-table.coins-rating-area .flex-table-row .item .hint,
.account .flex-table.coins-rating-area .table-row .item .hint {
  display: none;
}

.account .flex-table.coins-rating-area .flex-table-row .item.left,
.account .flex-table.coins-rating-area .table-row .item.left {
  text-align: left;
}

.account .flex-table.coins-rating-area .flex-table-row .item:nth-child(1) {
  width: 30px;
}

.account .flex-table.coins-rating-area .flex-table-row .item:nth-child(2) {
  width: calc(35% - 30px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.account .flex-table.coins-rating-area .flex-table-row .item:nth-child(3) {
  width: 15%;
}

.account .flex-table.coins-rating-area .flex-table-row .item:nth-child(4) {
  width: 15%;
}

.account .flex-table.coins-rating-area .flex-table-row .item:nth-child(5) {
  width: 15%;
}

.account .flex-table.coins-rating-area .flex-table-row .item:nth-child(6) {
  width: 10%;
}

.account .flex-table.coins-rating-area .flex-table-row .item:nth-child(7) {
  width: 10%;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(1) {
  width: 2rem;
  justify-content: center;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(2) {
  width: 14rem;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(3) {
  width: 8rem;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(4) {
  width: 6rem;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(5) {
  width: 6rem;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(6) {
  width: 10rem;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(7) {
  width: 10rem;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(8) {
  width: 10rem;
}

.account .flex-table.coins-rating-area .table-row .item:nth-child(9) {
  flex: 1;
  width: 180px;
  margin-right: 1rem;
  justify-content: center;
  white-space: nowrap;
}

.account .flex-table.coins-rating-area .table-row .item.wide {
  flex-grow: 1;
}

.account .flex-table.coins-rating-area .flex-table-row .item.with-image:nth-child(2) img,
.account .flex-table.coins-rating-area .table-row .item.with-image:nth-child(2) img {
  display: flex;
  height: 30px;
  width: 30px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-right: .5rem;
}

.account .flex-table.coins-rating-area .flex-table-row .item.good,
.account .flex-table.coins-rating-area .table-row .item.good {
  color: #00B74A;
}

.account .flex-table.coins-rating-area .flex-table-row .item.low,
.account .flex-table.coins-rating-area .table-row .item.low {
  color: #DB4437;
}

.account .flex-table.coins-rating-area .flex-table-row .item img,
.account .flex-table.coins-rating-area .table-row .item img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-right: .5rem;
}

.account .flex-table.coins-rating-area.ratings-area .table-row {
  width: 100%;
  padding: .5rem
}

.account .flex-table.coins-rating-area.ratings-area .table-row .item:first-child {
  justify-content: flex-start;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .item {
  justify-content: flex-end;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .item:nth-child(1) {
  width: 200px;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .item:nth-child(2) {
  width: 6rem;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .item:nth-child(3) {
  width: 8rem;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .item:nth-child(4) {
  width: 6rem;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .item:nth-child(5) {
  min-width: 6rem;
  width: calc(100% - 20rem - 200px);
}

.account .flex-table.coins-rating-area.ratings-area .table-row .item.wide:nth-child(5) {
  min-width: 6rem;
  width: auto;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .title .action {
  font-size: 1.1rem;
  line-height: 1.25;
  font-weight: 500;
  margin-bottom: 0.125rem;
  color: #323232;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .title .date {
  font-size: .75rem;
  color: #757575;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .amount-profit p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .amount-profit p:last-child {
  font-size: .75rem;
  font-weight: 500;
  color: #757575;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .amount-profit p.good {
  color: #00B74A;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .amount-profit p.low {
  color: #DB4437;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .actions .mini-button {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: #2A59FF;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  transition: background .3s ease-out;
  will-change: background;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .actions .mini-button svg {
  width: 0.8rem;
  height: 0.8rem;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .actions .mini-button:hover,
.account .flex-table.coins-rating-area.ratings-area .table-row .actions .mini-button:active {
  background: #0077c6;
  outline: 0;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .actions .mini-button.delete {
  background: #DB4437;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .actions .mini-button.delete:hover,
.account .flex-table.coins-rating-area.ratings-area .table-row .actions .mini-button.delete:active {
  background: #c63427;
}

.account .flex-table.coins-rating-area.ratings-area .table-row .actions .mini-button:not(:last-child) {
  margin-right: .5rem;
}

.account .muted-text {
  color: rgb(128, 138, 157);
}

.account .project-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-weight: 500;
}

.account .project-token {
  max-width: 3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}

@media (max-width:768px) {
  .account .coinsrating__title-3 {
    margin-bottom: 1rem;
  }

  .account .flex-table.coins-rating-area .flex-table-row,
  .account .flex-table.coins-rating-area .table-row {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
  }

  .account .flex-table.coins-rating-area .flex-table-row:first-child,
  .account .flex-table.coins-rating-area .table-row:first-child {
    display: none;
  }

  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(1),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(1) {
    display: none;
  }

  .account .flex-table.coins-rating-area .flex-table-row .item,
  .account .flex-table.coins-rating-area .table-row .item {
    justify-content: flex-start;
  }

  .account .flex-table.coins-rating-area .flex-table-row .item:not(:last-child),
  .account .flex-table.coins-rating-area .table-row .item:not(:last-child) {
    margin-bottom: .5rem;
  }

  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(2),
  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(3),
  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(4),
  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(5),
  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(6),
  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(7),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(2),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(3),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(4),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(5),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(6),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(7),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(8) {
    width: 100%;
  }

  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(2),
  .account .flex-table.coins-rating-area .table-row .item:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.15rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .account .flex-table.coins-rating-area .flex-table-row .item:nth-child(2) img,
  .account .flex-table.coins-rating-area .table-row .item:nth-child(2) img {
    width: 40px;
    height: 40px;
    object-position: center;
    object-fit: cover;
    margin-right: .5rem;
    display: flex;
    border-radius: 50%;
  }

  .account .flex-table.coins-rating-area .flex-table-row .item .hint,
  .account .flex-table.coins-rating-area .table-row .item .hint {
    display: flex;
    margin-right: .25rem;
  }

  .account .flex-table.coins-rating-area .table-row .item:nth-child(9) .hint {
    display: none;
  }

  .account .flex-table.coins-rating-area .flex-table-row .item,
  .account .flex-table.coins-rating-area .table-row .item {
    justify-content: flex-start;
    align-items: center;
  }

  .account .flex-table.coins-rating-area .flex-table-row,
  .account .flex-table.coins-rating-area .table-row {
    border-radius: .25rem;
    background: #f6f7fb;
    transition: background .3s ease-out;
    will-change: background;
  }

  .account .flex-table.coins-rating-area .flex-table-row:hover,
  .account .flex-table.coins-rating-area .flex-table-row:active,
  .account .flex-table.coins-rating-area .flex-table-row:focus,
  .account .flex-table.coins-rating-area .table-row:hover,
  .account .flex-table.coins-rating-area .table-row:active,
  .account .flex-table.coins-rating-area .table-row:focus {
    background: #e6f0fd;
    outline: none;
  }

  .account .flex-table.coins-rating-area .flex-table-row:nth-child(2n),
  .account .flex-table.coins-rating-area .table-row:nth-child(2n) {
    background: #f6f7fb;
  }

  .account .flex-table.coins-rating-area .flex-table-row:not(:last-child),
  .account .flex-table.coins-rating-area .table-row:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.account .error-hint {
  color: #fe0000;
  font-size: 70%;
  margin-bottom: 0;
  font-weight: 400;
  margin-top: .25rem;
}

.account .pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 1.5;
}

/* Input */
.account .pure-material-checkbox>input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.account .pure-material-checkbox>span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.account .pure-material-checkbox:hover>span::before,
.account .pure-material-checkbox:active>span::before,
.account .pure-material-checkbox:focus>span::before {
  background-color: #E1F5FE;
  border-color: #81D4FA;
}

/* Box */
.account .pure-material-checkbox>span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px;
  /* Safari */
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.account .pure-material-checkbox>span::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 2px;
  width: 10px;
  height: 6px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.account .pure-material-checkbox>input:checked,
.account .pure-material-checkbox>input:indeterminate {
  background-color: rgb(33, 150, 243);
}

.account .pure-material-checkbox>input:checked+span::before,
.account .pure-material-checkbox>input:indeterminate+span::before {
  border-color: rgb(33, 150, 243);
  background-color: rgb(33, 150, 243);
}

.account .pure-material-checkbox>input:checked+span::after,
.account .pure-material-checkbox>input:indeterminate+span::after {
  border-color: rgb(255, 255, 255);
}

.account .pure-material-checkbox>input:indeterminate+span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.account .pure-material-checkbox:hover>input {
  opacity: 0.04;
}

.account .pure-material-checkbox>input:focus {
  opacity: 0.12;
}

.account .pure-material-checkbox:hover>input:focus {
  opacity: 0.16;
}

/* Active */
.account .pure-material-checkbox>input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.account .pure-material-checkbox>input:active+span::before {
  border-color: rgb(33, 150, 243);
}

.account .pure-material-checkbox>input:checked:active+span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.account .pure-material-checkbox>input:disabled {
  opacity: 0;
}

.account .pure-material-checkbox>input:disabled+span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}

.account .pure-material-checkbox>input:disabled+span::before {
  border-color: currentColor;
}

.account .pure-material-checkbox>input:checked:disabled+span::before,
.account .pure-material-checkbox>input:indeterminate:disabled+span::before {
  border-color: transparent;
  background-color: currentColor;
}

.account .img-preview {
  width: 16em;
  height: 16em;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 5px;
  border: 2px solid #4c8bf5;
  border-radius: 50%;
}

.account .dropify-filename-inner,
.account .dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-infos-message::before,
#profile-photo .dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-infos-message,
#site-photo .dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-infos-message {
  display: none !important;
}

.account .dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-infos-message {
  margin-top: 0 !important;
  padding-top: 0 !important;
  opacity: .7 !important;
}

#img-load-bar {
  display: none;
  margin-bottom: .5em;
}

.account .dropify-wrapper {
  height: 225px !important;
  width: 225px !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50% !important;
  border-color: rgba(15, 108, 251, .7);
}

.account .dropify-wrapper .dropify-clear {
  top: initial !important;
  bottom: 25px !important;
  right: 46px !important;
  border-radius: .25rem;
}

.account .dropify-wrapper .dropify-clear:hover,
.account .dropify-wrapper .dropify-clear:active,
.account .dropify-wrapper .dropify-clear:focus {
  cursor: pointer;
}

.account .dropify-wrapper .dropify-preview .dropify-render img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50% !important;
}

.account .dropify-wrapper .dropify-preview {
  padding: 2px !important;
}

.account .dropify-wrapper .dropify-preview .dropify-infos {
  background: rgba(0, 0, 0, .5);
}

.account .projects-board__logo {
  width: 250px;
}

.account .projects-board__side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 250px);
}

.account .projects-board__all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.account .select2-container {
  width: 100% !important;
}

.account .select2-container .select2-selection--single {
  height: 31.5px !important;
}

.account .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 31.5px !important;
}

.account .tox-notification {
  display: none !important;
}

.account .desc-p {
  font-size: .85rem;
  margin-bottom: .25rem;
}

.account .dropify-wrapper .dropify-message p {
  font-size: .75rem;
}

.account .my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media (max-width:767.85px) {
  .account .dropify-wrapper .dropify-clear {
    background: #f5f5f5;
    color: #616161;
    border-color: #616161;
    opacity: 1;
  }

  .account .projects-board__wrap {
    margin-bottom: 1.5rem;
  }

  .account .projects-board__block:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .account .dropify-wrapper .dropify-preview .dropify-infos {
    background: none;
  }

  .account .projects-board__logo {
    width: 100%;
    margin-bottom: 1rem;
  }

  .account .projects-board__side {
    width: 100%;
  }

  .account .inline-text-field-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .account .inline-text-field-container.w-33 {
    width: 100%;
  }

  .account .select2-container .select2-selection--single .select2-selection__rendered {
    text-align: left;
  }

  .account .projects-board__title {
    text-align: left;
  }

  .account .pure-material-checkbox {
    text-align: left;
  }

  .account .desc-p {
    text-align: left;
  }

  .account .dropify-wrapper.touch-fallback {
    height: 175px !important;
    width: 175px !important;
  }

  .account .dropify-wrapper.touch-fallback .dropify-message {
    padding: 0;
  }

  .account .dropify-wrapper .dropify-message {
    top: 15%;
  }
}

.account .language-and-entrance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.account .language-and-entrance__language {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 1.75rem 0;
}

.account .outter-site .language-and-entrance__language .menu-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: auto;
  padding: 0;
  font-size: 1rem;
}

.account .language-and-entrance__language .menu-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.25rem;
  padding: 1rem;
  background: #EEEEEE;
  border-radius: .25rem;
  position: relative;
}

@media (max-width: 767.68px) {
  .account .sub-menu-link {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -10rem;
    left: 0;
    background: #eee;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem !important;
    border-radius: .25rem .25rem 0 0;
    transform: translateY(250px);
    transition: opacity .3s ease-out, transform .3s ease-out;
    will-change: transform, opacity;
  }

  .account .menu-link.active+.sub-menu-link,
  .account .menu-link.active+.sub-menu-link {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .account .menu-link svg {
    transition: transform .3s ease-out;
    will-change: transform;
  }

  .account .menu-link.active svg,
  .account .menu-link.active svg {
    transform: rotate(180deg);
  }

  .account .sub-menu-link li {
    padding: .25rem;
    border-radius: .25rem;
    width: calc(33% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    background: #f5f5f5;
    transition: background .3s ease-out, color .3s ease-out;
    will-change: background, color;
  }

  .account .sub-menu-link li:hover,
  .account .sub-menu-link li:active,
  .account .sub-menu-link li:focus {
    background: #2A59FF;
    color: #fff;
  }

  .account .sub-menu-link li:not(:nth-child(3n)) {
    margin-right: 1rem
  }

  .account .sub-menu-link li a {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.account .over-hidden {
  overflow: hidden;
}

.account .loadingIndicator {
  animation: 2s linear infinite svg-animation;
  max-width: 100px;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg)
  }
}

.account .loadingIndicator circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 15px;
  transform-origin: 50% 50%;
}

@keyframes circle-animation {

  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

.account .outter-site .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  visibility: visible;
  transition: transform .3s ease-out, opacity .3s ease-out;
  will-change: transform, opacity;
}

.account .outter-site .menu__item:not(:last-of-type) {
  margin-right: 30px;
}

.account .outter-site .menu__item a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #434343;
  font-weight: 400;
  font-size: 1rem;
  -webkit-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;

}

.account .outter-site .menu__item .bubble {
  margin-left: 0.25rem;
  background: #2A59FF;
  color: #fff;
  border-radius: 5rem;
  padding: 0.25rem 0.75rem;
  font-size: .75rem;
}

.account .outter-site .menu__item a:hover,
.account .outter-site .menu__item a:active,
.account .outter-site .menu__item a:focus {
  color: #2A59FF;
}

.account .outter-site .language-and-entrance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.account .outter-site .language-and-entrance__language {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
  position: relative;
  padding: 1.75rem 0;
}

.account .outter-site .language-and-entrance__entrance {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
}

.account .outter-site .language-and-entrance__entrance a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.account .outter-site .language-and-entrance__entrance a svg {
  margin-right: .25rem;
}

.account .outter-site .menu-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.account .outter-site .menu-link .down-icon {
  margin-left: .25rem;
  transition: transform .3s ease-out;
  will-change: transform;
}

.account .outter-site .header__menu li {
  padding: 1.75rem 0
}

.account .outter-site .header__menu li.auth,
.account .outter-site .header__menu li.languages {
  display: none;
}

.account .outter-site .sub-menu-container {
  display: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -150px;
  left: -50%;
  background: #fff;
  padding: 0;
  border-radius: 0 0 4px 4px;
  border-top: 3px solid #2A59FF;
  box-shadow: 0 3px 6px rgba(155, 155, 155, 0.16), 0 3px 6px rgba(155, 155, 155, 0.23);
  opacity: 0;
  transition: transform .3s ease-out, opacity .15s ease-out;
  will-change: opacity, transform;
  z-index: 11;
}

.account .outter-site .sub-menu-container li {
  width: 90px;
  text-align: center;
  padding: .5rem 0;
  background: #fff;
  color: #434343;
  transition: background .3s ease-out, color .3s ease-out;
  will-change: background, color;
}

.account .outter-site .sub-menu-container li.active {
  color: #2A59FF;
}

.account .outter-site .sub-menu-container li:hover,
.account .outter-site .sub-menu-container li:active,
.account .outter-site .sub-menu-container li:focus {
  background: #F5F5F5;
  color: #2A59FF;
}

.account .outter-site .language-and-entrance__language:hover .sub-menu-container,
.account .outter-site .language-and-entrance__language:active .sub-menu-container,
.account .outter-site .language-and-entrance__language:focus .sub-menu-container {
  display: flex;
  transform: translateY(225px);
  opacity: 1;
}

.account .outter-site .language-and-entrance__language:hover .menu-link .down-icon,
.account .outter-site .language-and-entrance__language:active .menu-link .down-icon,
.account .outter-site .language-and-entrance__language:focus .menu-link .down-icon {
  transform: rotate(180deg);
}

.account .outter-site .burger {
  display: none;
  z-index: 100;
}

.account .outter-site .burger span {
  display: flex;
  width: 30px;
  height: 4px;
  background-color: #2A59FF;
  margin-bottom: 6px;
  border-radius: 6rem;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.account .outter-site .burger.active span:nth-of-type(2) {
  width: 70%;
}

.account .outter-site .burger.active span:nth-of-type(3) {
  width: 40%;
}

.account .outter-site .menu.active {
  display: block;
  position: fixed;
  color: #fff;
  background-color: #0048ff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem;
  font-size: 2rem;
  line-height: 1.5;
  z-index: 100;
  overflow: auto;
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.account .menu.active .menu__item a {
  color: #fff;
}

.account .outter-site .logo__img {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 3rem;
  width: auto;
}

.account .outter-site .logo__img img {
  width: 200px;
  margin-right: 1rem;
}

.account .container {
  max-width: 1230px;
  padding: 0 20px;
  margin: 0 auto;
}

.account .outter-site .header {
  padding: 0;
  display: block;
  color: #1e1d1d;
  -webkit-box-shadow: 0 3px 3px #f0f0f0;
  box-shadow: 0 3px 3px #f0f0f0;
}

.account .outter-site .header__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1210px) {

  .account .outter-site .header__menu li.auth,
  .account .outter-site .header__menu li.languages {
    display: flex;
    flex-direction: column;
  }

  .account .outter-site .header__menu li.auth a:not(:last-child) {
    margin-bottom: 1rem;
  }

  .account .outter-site .menu__item:not(:last-of-type) {
    margin-right: 0;
  }

  .account .outter-site .header__menu li {
    padding: 1rem 0;
  }

  .account .outter-site .header__menu li a {
    font-size: 1.75rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .account .outter-site .header__menu li a svg {
    margin-right: .5rem;
  }

  .account .outter-site .menu__item.languages {
    background: #0072ff;
    padding: 1rem;
    position: relative;
  }

  .account .outter-site .sub-menu-link {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -13rem;
    left: 0;
    background: #0072ff;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    border-radius: .25rem .25rem 0 0;
    transform: translateY(250px);
    transition: opacity .3s ease-out, transform .3s ease-out;
    will-change: transform, opacity;
  }

  .account .outter-site .menu__item.languages.active .sub-menu-link,
  .account .menu__item.languages.active .sub-menu-link {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .account .outter-site .menu__item.languages.active a svg,
  .account .menu__item.languages.active a svg {
    transform: rotate(90deg);
  }

  .account .outter-site .sub-menu-link li {
    padding: .25rem;
    border-radius: .25rem;
    width: calc(33% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    background: #2A59FF;
    transition: background .3s ease-out;
    will-change: background;
  }

  .account .outter-site .sub-menu-link li:hover,
  .account .sub-menu-link li:active,
  .account .sub-menu-link li:focus {
    background: #00ACEE;
  }

  .account .outter-site .sub-menu-link li:not(:nth-child(3n)) {
    margin-right: 1rem
  }

  .account .outter-site .header__menu .sub-menu-link li a {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .account .outter-site .menu__item.languages a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .account .outter-site .menu__item.languages a svg {
    font-size: 2rem;
    transform: rotate(-90deg);
    transition: transform .3s ease-out;
    will-change: transform;
  }

  .account .outter-site .menu__item.languages a:hover,
  .account .outter-site .menu__item.languages a:active,
  .account .outter-site .menu__item.languages a:focus {
    color: #fff;
  }

  .account .outter-site main {
    padding-top: 75px;
  }

  .account .outter-site .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 20;
    height: 75px;
    background: #fff;
    z-index: 20;
  }

  .account .outter-site .header .container,
  .account .outter-site .first-prepage .container {
    width: 100%;
  }

  .account .social__link {
    justify-content: center;
  }

  .account .outter-site .language-and-entrance {
    display: none;
  }

  .account .outter-site .menu {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-850px);
  }

  .account .outter-site .menu .language-and-entrance {
    display: none;
  }

  .account .outter-site .burger {
    display: block;
  }
}

@media (max-width: 414px) {
  .account .outter-site .header__content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.account .custom-dropzone .image-preview {
  width: 100%;
  height: 100% !important;
  margin: 0 !important;
  border-radius: 50% !important;
}

.account .custom-dropzone .image-preview ngx-dropzone-label {
  bottom: 3rem;
  font-size: .75rem;
}

.account .custom-dropzone .image-preview ngx-dropzone-remove-badge {
  right: calc(50% - 15px);
  top: 1rem;
  width: 30px;
  height: 30px;
}

.account .custom-dropzone .image-preview>img {
  width: 100% !important;
  height: 100% !important;
  object-position: center !important;
  object-fit: cover !important;
}

.account .services-area .service-item .icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.account .select2-results__option--highlighted .address-select .price-area p {
  color: #fff !important;
}

.account .select2-results__option--highlighted .address-select .price-area .code-text {
  color: #e0e0e0 !important;
}

.account .no-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.account .no-items svg {
  width: 3rem;
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #757575
}

.account .no-items h4 {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #323232;
}

.account .select-bigger {
  min-width: 150px;
}

.account .tooltip {
  position: relative;
}

.account .tooltip:before,
.account .tooltip:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.account .tooltip:after {
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, .75);
  border-left: 6px solid transparent;
  content: '';
  height: 0;
  top: -0.37rem;
  left: 0.4rem;
  width: 0;
  transform: translate3d(0, 4px, 0) rotate(180deg);
  transition: all .1s ease-in-out;
}

.account .tooltip:before {
  background: rgba(60, 60, 60, .9);
  border-radius: 0.25rem;
  color: #fff;
  content: attr(title);
  font-size: .85rem;
  padding: 0.3rem 0.6rem;
  top: -2rem;
  white-space: nowrap;
  transform: scale3d(.2, .2, 1);
  transition: all .2s ease-in-out;
}

.account .tooltip:hover:before,
.account .tooltip:hover:after {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.account .tooltip:hover:after {
  transform: scale3d(1, 1, 1) rotate(180deg);
  transition: all .2s .1s ease-in-out;
}
[text-tooltip] {
  position: relative;
  z-index: 0;
}

[text-tooltip]:before,
[text-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  pointer-events: none;
  transition: 0.2s ease-out;
  will-change: transform;
}

[text-tooltip]:before {
  content: attr(text-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: .5rem;
  font-family: "Roboto";
  font-size: .85rem;
  line-height: 1.25;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg,
      rgba(59, 72, 80, 0.44),
      rgba(59, 68, 75, 0.44),
      rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.025rem;
  text-align: center;
  white-space: pre-wrap;
  text-transform: none;
  transform: translate(-50%, -5px) scale(0.5);
}
.wide-tooltip[text-tooltip]::before {
  min-width: 14rem;
  max-width: 22rem;
  line-height: 1.35;
  white-space: initial;
}

[text-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s;
  transform-origin: top;
  transform: translateX(-50%) scaleY(0);
}

[text-tooltip]:hover:before,
[text-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

[text-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}

[text-tooltip]:hover:after {
  transition-delay: 0.4s;
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
[text-tooltip][flow^="left"]:before,
[text-tooltip][flow^="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

[text-tooltip][flow^="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[text-tooltip][flow^="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

[text-tooltip][flow^="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[text-tooltip][flow^="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

[text-tooltip][flow^="right"]:before,
[text-tooltip][flow^="right"]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[text-tooltip][flow^="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[text-tooltip][flow^="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[text-tooltip][flow^="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[text-tooltip][flow^="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

[text-tooltip][flow^="bottom"]:before,
[text-tooltip][flow^="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[text-tooltip][flow^="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}
[text-tooltip][flow^="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[text-tooltip][flow^="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}

.dark .pu-skeleton {
  background-color: rgb(39 48 70) !important;
  background-image: linear-gradient(90deg, rgb(39 48 70), rgb(45 54 78), rgb(39 48 70)) !important;
}

.loading {
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.tooltips {
  display: none;
}
.account .info-area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem;
  background: rgb(0 153 255 / 5%);
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.account .info-area .icon-area {
  width: 2rem;
  height: 2rem;
  margin-right: .5rem;
  flex-shrink: 0;
}

.account .info-area .icon-area i {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  color: #2A59FF;
}

.account .info-area .text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.account .info-area .text-area .h6 {
  font-size: 1.15rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.account .info-area .text-area p.desc {
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
}

.account .info-area .text-area p.desc:not(:last-child) {
  margin-bottom: 1rem;
}

.account .modal-area {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
}

.account .modal-area .modal-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, .5);
  transition: opacity, .3s ease-out;
  will-change: opacity;
}

.account .modal-area.show,
.account .modal-area.show .modal-bg,
.account .modal-area.show .modal-body {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.account .modal-area .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30rem;
  padding: 1rem;
  border-radius: 0.3rem;
  transform: translateY(100%);
  transition: transform .3s ease-out;
  will-change: transform;
}

.account .modal-area .modal-body .modal-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.account .modal-area .modal-body .modal-inner .close-area {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #323232;
  opacity: .5;
  transition: opacity .3s ease-out;
  will-change: opacity;
}

.account .modal-area .modal-body .modal-inner .close-area:hover,
.account .modal-area .modal-body .modal-inner .close-area:active {
  opacity: 1
}

.account .modal-area .modal-body .modal-inner .h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.account .modal-area .modal-body .modal-inner .desc {
  margin-bottom: 3rem;
  font-size: 1rem;
  line-height: 1.25;
}

.account .modal-area .modal-body .modal-inner .desc .bolder {
  font-weight: 500;
}

.account .modal-area .modal-body .modal-inner .buttons-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.account .modal-area .modal-body .modal-inner .buttons-area button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background: #eeee;
  color: #323232;
  font-weight: 500;
  transition: background .3s ease-out;
  will-change: background;
}

.account .modal-area .modal-body .modal-inner .buttons-area button:not(:last-child) {
  margin-right: 1rem;
}

.account .modal-area .modal-body .modal-inner .buttons-area button:hover,
.account .modal-area .modal-body .modal-inner .buttons-area button:active {
  background: #e9e9e9;
}

.account .modal-area .modal-body .modal-inner .buttons-area button.apply-button {
  background: #2A59FF;
  color: #fff;
}

.account .modal-area .modal-body .modal-inner .buttons-area button.apply-button:hover,
.account .modal-area .modal-body .modal-inner .buttons-area button.apply-button:active {
  background: #23a7ff;
}

.account .ngx-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.account .ngx-pagination li {
  border-radius: 0.25rem;
}

.account .switch {
  display: block;
  position: relative;
  margin: auto;
  width: 40px;
  height: 25px;
  cursor: pointer;
  overflow: hidden;
}

.account .switch input {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 0;
  height: 0;
}

.account .switch input+span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #cdcdcd;
  border-radius: 3rem;
}

.account .switch input:checked+span {
  background: #2A59FF;
}

.account .switch input+span:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all .3s;
}

.account .switch input:checked+span:before {
  left: 18px;
}
.pagination {
  width: 100%;
  margin-top: 2rem;
}
.account .pagination .select {
  margin-bottom: 0;
  width: auto;
}
.filepond,
.filepond--root,
.filepond--root .filepond--drop-label {
  width: 100%;
}

.filepond--credits {
  display: none;
}

.filepond--panel-root {
  background-color: #fafafa !important;
  border: 2px dashed rgb(204, 204, 204);
  border-radius: 10px;
  transition: border .3s ease-out, background-color .3s ease-out;
  will-change: background-color, border;
}

.filepond--drop-label,
.filepond--drop-label label {
  transition: font-size .3s ease-out;
  will-change: font-size;
}

.filepond--root:hover,
.filepond--root:active,
.filepond--root:hover .filepond--drop-label,
.filepond--root:active .filepond--drop-label {
  cursor: pointer
}

.filepond--root:hover .filepond--drop-label,
.filepond--root:active .filepond--drop-label {
  font-size: 1.05rem;
}

.filepond--root:hover .filepond--panel-root,
.filepond--root:active .filepond--panel-root {
  background-color: #f5f5f5 !important;
  border: 2px dashed #88abf0;
}
.warning-area, .info-area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem;
  border-radius: .5rem;
  background: rgb(255 169 0 / 25%);
  margin-bottom: 1rem;
}
.info-area {
  background: rgb(3 169 244 / 25%);
}
.warning-area .icon-area,
.info-area .icon-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 2rem;
  margin-right: 1rem;
}
.warning-area .icon-area i,
.info-area .icon-area i {
  font-size: 2rem;
  color: #FFA900
}
.info-area .icon-area i {
  color: #03a9f4
}
.warning-area .text-area,
.info-area .text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
}
.warning-area .text-area .h6,
.info-area .text-area .h6,
.warning-area .text-area .h4,
.info-area .text-area .h4 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: .5rem;
}
.warning-area .text-area p.desc,
.warning-area p.warning,
.info-area .text-area p.desc,
.info-area p.warning {
  font-size: .85rem;
  font-weight: 400;
  line-height: 1.5;
}
.warning-area .text-area p.desc .bold,
.warning-area p.warning  .bold,
.info-area .text-area p.desc  .bold,
.info-area p.warning  .bold {
  font-weight: 600;
}
.warning-area .text-area p.desc i,
.warning-area p.warning i,
.info-area .text-area p.desc i,
.info-area p.warning i {
  margin-right: .5rem;
}
.warning-area .text-area p.desc:not(:last-child),
.warning-area p.warning:not(:last-child),
.info-area .text-area p.desc:not(:last-child),
.info-area p.warning:not(:last-child) {
  margin-bottom: 1rem;
}
.warning-area .text-area .buttons-area,
.info-area .text-area .buttons-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.warning-area .text-area .buttons-area button,
.warning-area .text-area .buttons-area a,
.info-area .text-area .buttons-area a,
.info-area .text-area .buttons-area button {
  width: auto;
  padding: 0.75rem 1.5rem;
  font-size: .85rem;
  line-height: 1;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  select,
  select:focus,
  textarea,
  textarea:focus,
  input,
  input:focus {
    font-size: 16px;
  }
}