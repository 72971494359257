.error-area {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    max-width: 890px;
    margin: 0 auto;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    
}
.error-area .logo-header {
    color: #c9c9c9;
    margin-bottom: 2rem;
    transition: color .3s ease-out;
    will-change: color;
}
.error-area .logo-header:hover,
.error-area .logo-header:active,
.error-area .logo-header:focus {
    color: #666;
}
.error-area .logo-header svg {
    width: 180px;
}
.error-text h1 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 2rem;
    text-align: center;
}
.error-text h2 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 2rem;
}
.error-text h3 {
    font-size: 5rem;
    font-weight: 900;
    line-height: 1;
    margin-bottom: .5rem;
}
.error-message {
    border-radius: .5rem .5rem 0 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
}
.citation-section {
    display: -webkit-box;
    display: flex;
    padding: 3rem 2.5rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    background: #f4f8fe;
    border-radius: .5rem .5rem 0 0;
}
.dark .citation-section {
    background: #181b27;
}
.citation-section div:first-child {
    width: 60%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
}
.error-image {
    width: 40%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
}
.error-image img {
    width: 100%;
    max-width: 325px;
    pointer-events: none;
}
.citation {
    text-align: left;
    line-height: 1.5em;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 700;
    position: relative;
}
.citation:before {
    content: '\201C';
    position: absolute;
    top: 0.85rem;
    left: -1.25rem;
    color: #ccc;
    opacity: .2;
    font-size: 6rem;
    z-index: 0;
}
.citation-author {
    text-align: right;
    font-size: .9rem;
    font-weight: 700;
    color: #eee;
    max-width: 90%;
}
.img-fluid {
    padding-top: 0;
    padding-bottom: 1rem;
    width: 150px;
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    will-change: transform;
    pointer-events: none;
}
.img-fluid:hover, .img-fluid:active, .img-fluid:focus {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.error-description {
    background: #2A59FF;
    padding: 1rem;
    border-radius: 0 0 .5rem .5rem;
}
.error-description p {
    color: #fff;
    font-size: .85rem;
    font-weight: 400;
    text-align: center;
}
.error-footer {
    display: -webkit-box;
    display: flex;
    background: transparent;
    padding: 0;
    margin-top: 1.5rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
}
.error-footer .footer-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.error-footer .bx-minus {
    color: #bdbdbd;
}
.dark .error-footer .bx-minus {
    color: #535b7f;
}
.error-footer .footer-link i {
    color: #757575;
    -webkit-transition: color .3s ease-out;
    transition: color .3s ease-out;
    will-change: color;
    margin-right: .25rem;
}
.dark .error-footer .footer-link i {
    color: #a4b0e7;
}
.error-footer a {
    color: #434343;
    font-size: 80%;
    font-weight: 500;
    -webkit-transition: color .3s ease-out;
    transition: color .3s ease-out;
    will-change: color;
}
.error-footer a:hover, .error-footer a:active, .error-footer a:focus,
.error-footer a:hover i, .error-footer a:active i, .error-footer a:focus i {
    color: #2A59FF;
}
.dark .error-footer a {
    color: #778ecb;
}
.dark .error-footer a:hover, .dark .error-footer a:active, .dark .error-footer a:focus,
.dark .error-footer a:hover i, .dark .error-footer a:active i, .dark .error-footer a:focus i {
    color: #fff
}
.mx-3 {
    margin-left: 1rem;
    margin-right: 1rem;
}

@media(max-width: 767px) {
    .error-area {
        min-height: auto;
        max-width: 90%;    
    }
    .citation-section {
        padding: 1rem;
    }
    .img-fluid {
        padding-top: 1rem;
        padding-bottom: .5rem;
        width: 100px;
    }
    .citation-section div:first-child {
        width: 100%;
    }
    .error-image {
        width: 100%;
    }
    .citation-section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
    }
    .error-text h1 {
        font-size: 2rem;
        margin-bottom: 0;
    }
    .error-text h2 {
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }
    .error-description p {
        font-size: .9rem;
    }
    .error-footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
    }
    .error-footer a:not(:last-child) {
        margin-bottom: .25rem;
    }
    .error-footer svg:not(:last-child) {
        margin-bottom: .25rem;
    }
}